import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from "axios";

const ResetPassword = () => {
    const { token } = useParams(); // Récupérer le token depuis l'URL
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas");
            return;
        }

        try {
            const response = await axios.post(`https://resolvia.io/api/auth/reset-password/${token}`, { password });
            setSuccess(response.data.msg);
            setTimeout(() => navigate('/login'), 2000); // Rediriger après succès
        } catch (err) {
            setError('Erreur lors de la réinitialisation du mot de passe');
        }
    };

    return (
        <div>
            <div className=" pt-12 background flex flex-col justify-center min-h-screen">
                <img
                    src="https://i.ibb.co/n6Sk79h/LOGO-1200-x-800-px.png"
                    className="w-80 h-auto mx-auto mt-32"
                    alt="Logo"
                />
                <div className="w-7/12 bg-[#DD6E79]  rounded-lg m-auto flex flex-col items-center justify-center space-y-6 h-auto py-6">
                    <h2 className="text-2xl text-white font-black text-center pt-4 pb-6">
                        Réinitialisez votre mot de passe
                    </h2>

                    {/* Formulaire */}
                    <form className="flex flex-col w-full items-center space-y-4" onSubmit={handleSubmit}>
                            <input
                                className="rounded-lg p-2 text-lg w-3/4"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={'Nouveau mot de passe'}
                                required
                            />
                            <input
                                className="rounded-lg p-2 text-lg w-3/4"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder={'Confirmez le mot de passe'}
                                required
                            />
                        <button
                            className="bg-[#1CBDB4] py-3 px-8 rounded-lg text-white font-bold hover:bg-[#c65a66] transition"
                            type="submit">Réinitialiser
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
