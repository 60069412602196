import React, {useState} from 'react';
import axios from "axios";
import {useNavigate, useParams} from 'react-router-dom';

function ForgottentPassword()   {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(''); // Message de succès après l'envoi de l'e-mail
    // const navigate = useNavigate(); // Utilisé pour rediriger après la connexion

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Appel à l'API pour envoyer l'e-mail de réinitialisation
            const response = await axios.post(`https://www.resolvia.io/api/auth/forgot-password`, { email });
            setSuccess(response.data.msg);
            setTimeout(() => navigate('/login'), 2000); // Rediriger après succès
        } catch (err) {
            setError(err.response?.data?.msg || 'Erreur lors de la tentative de réinitialisation.');
        }
    };

        return (
            <div className="pt-12 background flex flex-col justify-center min-h-screen">
                <img
                    src="https://i.ibb.co/n6Sk79h/LOGO-1200-x-800-px.png"
                    className="w-80 h-auto mx-auto mt-32"
                    alt="Logo"
                />
                <div
                    className="w-7/12 bg-[#1CBDB4] rounded-lg m-auto flex flex-col items-center justify-center  h-auto py-8">
                    <h2 className="text-2xl text-white font-black text-center pt-4 pb-6">Réinitialiser le mot de passe</h2>
                    {/* Formulaire */}
                    <form className="flex flex-col w-full items-center space-y-4" onSubmit={handleSubmit}>
                        <input
                            className="rounded-lg p-2 text-lg w-3/4"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}

                        />
                        <button
                            className="bg-[#DD6E79] py-3 px-8 rounded-lg text-white font-bold hover:bg-[#c65a66] transition"
                            type="submit">
                            Réinitialiser le mot de passe
                        </button>
                    </form>
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                </div>
            </div>
        );

}


export default ForgottentPassword;
