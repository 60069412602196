import React from 'react';
import './App.css';

const Modal = ({ onClose }) => {
    return (
         <div
            className="fixed bg-opacity-100 flex flex-col items-center justify-center w-2/3 h-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#DD6E79]
            p-6 rounded-lg shadow-rose-950 shadow-2xl transition transform scale-100">
            <div className="">
                <h2 className="text-xl font-bold mb-4 text-center">Votre rapport est disponible. </h2>
                <div className="text-xl text-center leading-9">
                    <p>Merci pour votre coopération. Le rapport a été généré et enregistré directement sur votre
                        appareil. Il se trouve dans le dossier "téléchargement"
                        sur votre ordinateur ou Mac; ou bien "fichiers" si vous utilisez un appareil iOS ou Android.
                        Si vous n'arrivez pas à trouver votre rapport, veuillez suivre les étapes du tutoriel à cette
                        adresse : </p><a>lien vidéo youtube tuto</a>
                </div>
                <div className="text-center mt-8 leading-5">
                    <p className="text-base">ResolviA peut faire des erreurs. En cas de doute, vous pouvez demander
                        conseil à un de nos Experts en bâtiment partenaire à</p><a className="text-emerald-800 text-lg"
                                                                                   href="mailto:h.colomer@assistance-expertise-batiment.com">cette
                    adresse.</a>
                </div>

            </div>
            <button onClick={onClose} className="mt-4 mx-auto bg-[#1CBDB4] text-white px-6 py-4 rounded">Fermer</button>

        </div>
    );
};

export default Modal;
