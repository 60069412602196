import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import Header from './Header';
import Chat from './Chat';
import Pdfgenerator from './Pdfgenerator';
import Modal from './Modal';
import {useAuth} from './AuthContext';
import useSendMessages from './hooks/useSendMessages';
import useImageUpload from './hooks/useImageUpload';
import {useNavigate} from 'react-router-dom';
import AppRoutes from "./Routes"; // Importez useNavigate
import axios from "axios";

function App({ onError }) {
  const navigate = useNavigate();
  const { authToken, logout } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const defaultMessage = {
    role: "assistant",
    content: "Bonjour, je suis Resolvia, l'assistante qui vous accompagne dans la résolution de vos dommages. \n\n" +
        "Pour m'aider à comprendre au mieux votre situation et procéder efficacement à l'élaboration de votre pré-rapport, pourriez-vous me fournir une description détaillée de ce qui " +
        "s'est passé ? \n\n" +
        "Veuillez inclure, si possible, les circonstances menant à l'incident, les dommages observés et toute autre information que vous jugerez pertinente."
  };
  const [sentImageUrls, setSentImageUrls] = useState([]);
  const [messages, setMessages] = useState([defaultMessage]);
  const messagesEndRef = useRef(null);
  const { uploadedImageUrls, setUploadedImageUrls, handleImageChange, removeImage, largeImageUrls } = useImageUpload();

  const { handleSend, error } = useSendMessages(
      inputValue,
      setInputValue,
      setIsLoading,
      setSentImageUrls,
      setUploadedImageUrls,
      uploadedImageUrls,
      messages,
      setMessages
  );

  const [showModal, setShowModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [generatedPDF, setGeneratedPDF] = useState(null);
  const userId = authToken.userId;

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, messagesEndRef]);
  
  
   useEffect(() => {
    // Appel à l'API pour vérifier si le PDF a déjà été généré
    const fetchPdfStatus = async () => {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(`/api/count-generations/${userId}`);
        if (response.data && response.data.hasGeneratedPDF) {
          setGeneratedPDF(response.data.hasGeneratedPDF);
          console.log(generatedPDF)
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de la génération du PDF :', error);
      } finally {
        console.log('ok')
      }
    };

    fetchPdfStatus();
  }, [userId]);

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  return (
      <div className="App relative">
      
      {generatedPDF > 0 &&
            <div>
              <div   className="fixed inset-0 flex flex-col items-center justify-center bg-[#DD6E79] space-y-4 w-1/2 h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-xl">
                <h1 className="text-2xl">Votre crédit est épuisé</h1>
                <div className="w-3/4 h-auto text-center space-y-3">
                <p>Oh non... On dirait que votre crédit est épuisé...</p>
                <p>Pour obtenir un nouveau crédit et ainsi pouvoir démarrer une nouvelle démarche, veuillez vous rendre sur ce lien :</p>
                <div className="py-4">
                  <a className="font-black" href="https://resolvia.systeme.io/paiement1" target="_blank">https://resolvia.systeme.io/paiement1</a>
                </div>
                
 
               <p>Et bonne nouvelle, pour vous remercier de votre fidélité et d'avoir utilisé notre solution, vos prochaines démarches seront au prix de 6,50 € au lieu de 9,00 €.</p>
 
              <p>Si vous souhaitez utiliser ResolviA à plus grande échelle et éviter d'avoir à régler chaque procédure, veuillez préciser votre demande et nous contacter à cette adresse :</p>
              <div>
              <a className="py-6 font-black" href="mailto:contact@resolvia.io" target="_blank">contact@resolvia.io</a>
              </div>
              
                </div >
                
              </div>

            </div>
        }
      
        <Header authToken={authToken} handleLogout={handleLogout}/>
        {authToken?.token ? ( // Si l'utilisateur est authentifié
            <div className="pt-24 m-auto min-h-screen w-full background">
              <Chat
                  messages={messages}
                  messagesEndRef={messagesEndRef}
                  isLoading={isLoading}
              />

              <div className="flex flex-wrap fixed bottom-24 space-x-2 px-4">
                {uploadedImageUrls.map((url, index) => (
                    <div key={index} className="relative m-2">
                      <img src={url} alt={`Uploaded ${index}`} className="w-auto h-[60px] object-cover"/>
                      <button
                          className="absolute top-1 right-1 bg-white bg-opacity-70 rounded-full text-sm p-1 cursor-pointer"
                          onClick={() => removeImage(url)}>&times;</button>
                    </div>
                ))}
                {largeImageUrls.map((url, index) => (
                    <div key={index} className="relative m-2 border-4 border-red-500">
                      <img src={url} alt={`Large ${index}`} className="w-auto h-[60px]"/>
                      <button
                          className="absolute top-1 right-1 bg-white bg-opacity-70 rounded-full text-sm h-5 w-5 p-1 cursor-pointer"
                          onClick={() => removeImage(url)}>&times;</button>
                    </div>
                ))}
              </div>

              <div className="bg-blue-300 relative">
                <form className="py-5 px-4 m-auto flex flex-row w-full fixed bottom-0 left-0 right-0 border-2 border-black"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSend(e, inputValue);
                      }}>
                  <input
                      className={`w-full rounded-lg border-[1px] border-[#DD6E79] h-14 m-auto bg-grey-400 pl-28 pr-20 text-[#DD6E79] ${generatedPDF > 0 ? 'bg-gray-300 text-white' : ''} `}
                      name="question" value={inputValue} onChange={(e) => setInputValue(e.target.value)}
                      placeholder="Posez vos questions ici"
                  />
                  <div className="m-auto flex flex-row">
                    <button type="submit" className="w-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#1CBDB4"
                           className="w-9 h-9 text-gray-500 hover:text-gray-800 absolute bottom-8 right-8">
                        <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"/>
                      </svg>
                    </button>
                    <Pdfgenerator messages={messages} sentImageUrls={sentImageUrls} setShowForm={setShowForm}
                                  setShowModal={setShowModal} setIsOver={setIsOver} />
                  </div>

                  <div>
                    <input type="file" id="file" name="file" accept="image/png, image/jpeg, .doc, .pdf, .xlsx"
                           onInput={handleImageChange} multiple={true} className="hidden"/>
                    <label htmlFor="file" className="w-auto absolute left-6 bottom-8">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                           stroke="#1CBDB4" className={`w-9 h-9`}>
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"/>
                      </svg>
                    </label>
                  </div>
                </form>
              </div>

              {showModal && <Modal onClose={() => setShowModal(false)} />}
            </div>
        ) : ( // Sinon, affiche les routes publiques
            <AppRoutes />
        )}
      </div>
  );
}

export default App;
