// PrivateRoute.js
import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from './AuthContext';

const PrivateRoute = ({ element: Element, ...rest }) => {
    const { authToken } = useAuth();

    return authToken?.token ? (
        <Element {...rest} />
    ) : (
        <Navigate to="/login" replace />
    );
};

export default PrivateRoute;
