import React from 'react';

function Header({ authToken, handleLogout }) {
    return (
        <div id="nav" className="h-auto w-full z-20 flex flex-row justify-between opacity-80 bg-[#E2AE81] py-2">
            <h1 className="mt-4 ml-4 text-3xl font-extrabold text-white tracking-wider  drop-shadow-2xl">
                RESOLViA
            </h1>
            <div className="flex flex-col justify-center">
                <div className="text-right mr-4">
                    <p className="text-xl">{authToken.userName}</p>
                </div>
                <div>
                    {authToken?.token && (
                        <button
                            className="mr-3 mt-3 p-3 bg-[#DD6E79] rounded-xl hover:bg-[#FF0018]"
                            onClick={handleLogout}
                        >
                            Déconnexion
                        </button>
                    )}
                    <button
                        className="mr-3 mt-3 p-3 bg-[#1CBDB4] rounded-xl hover:bg-emerald-600"
                    >
                        <a href="mailto:contact@resolvia.io">Support</a>
                    </button>
                </div>

            </div>
        </div>
    );
}

export default Header;
